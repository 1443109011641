import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCO5UhuYXuiThKXVFQW4OmfVZ2ToJP8QUo",
    authDomain: "pablomarte-d387d.firebaseapp.com",
    projectId: "pablomarte-d387d",
    storageBucket: "pablomarte-d387d.appspot.com",
    messagingSenderId: "686294244120",
    appId: "1:686294244120:web:48cdf3d8aa683f8eb3dc6d"
};
export default function initFirebase() {
    const app = initializeApp(firebaseConfig);
}